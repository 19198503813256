import "./CTA.scss";

export type CTAPropsType = {
  label: string;
  action: () => void;
};

export const CTA = ({ label, action }: CTAPropsType) => {
  return (
    <button onClick={action} className="shiny-cta w-fit">
      <span>{label}</span>
    </button>
  );
};
