import { aiTranslations } from "./aiPage/aiTranslations";
import { homeTranslations } from "./homePage/homeTranslations";

export const translationsStrings = {
  articles: {
    en: "Articles",
    de: "Artikel",
    es: "Artículos",
  },
  header: {
    contact: {
      en: "Contact",
      de: "Kontakt",
      es: "Contacto",
    },
  },
  ai: aiTranslations,
  home: homeTranslations,
};
