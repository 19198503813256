import React, { useEffect } from "react";

type TextMaskPropsType = {
  title: string;
};

export const TextMask = ({ title }: TextMaskPropsType) => {
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const mouseX = e.pageX;
      const mouseY = e.pageY;
      const traX = (4 * mouseX) / 570 + 50;
      const traY = (4 * mouseY) / 570 + 50;

      const titleElement = document.querySelector(".title") as HTMLElement;
      if (titleElement) {
        titleElement.style.backgroundPosition = `${traX}% ${traY}%`;
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div>
      <div
        className={`title text-left text-center text-6xl lg:text-[8rem] font-extrabold text-transparent bg-clip-text bg-[url('https://images.unsplash.com/photo-1558715841-07ae24e7ecac?q=80&w=2741&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-repeat bg-[10%_10%]`}
      >
        {title}
      </div>
    </div>
  );
};
