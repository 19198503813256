import { useNavigate } from "react-router";
import { getThemeColor } from "../../tailwindUtils";
import { LogoSvg } from "../../Utils/svgs/logo";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed w-fit -translate-x-1/2 top-4 left-1/2 z-[9999] flex justify-center">
      {/* Outer div for the gradient border */}
      <div className="relative flex items-center justify-center rounded-full bg-background p-[1px]  bg-gradient-to-r from-accent/30 to-white/30">
        {/* Inner content */}
        <div className="flex flex-row items-center justify-center gap-2 text-white h-fit rounded-full bg-background px-3 py-2 transition-all duration-300">
          <div className="relative flex items-center group">
            <LogoSvg fill={getThemeColor("white")} width="22px" height="22px" />
            <span className="group-hover:ml-2 text-sm font-light opacity-0 group-hover:opacity-100 max-w-0 group-hover:max-w-[80px] transition-all duration-300 ease-in-out whitespace-nowrap overflow-hidden">
              Millerio
            </span>
          </div>

          {/* Contact button */}
          <button
            onClick={() => navigate("/form/discovery")}
            className="text-sm font-medium text-background px-2 py-1 transition-all transition-300 rounded-full bg-accent hover:bg-white hover:text-background  shadow-sm"
          >
            Contact
          </button>
        </div>
      </div>
    </div>
  );
};
