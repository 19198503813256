import { TabContent, TabsSection } from "../../modules/TabsSection/TabsSection";
import RobotsImage from "../../Assets/services/robots.png";
import CompassImage from "../../Assets/services/compass.png";
import TreeImage from "../../Assets/services/tree.png";
import LaptopImage from "../../Assets/services/laptop.png";
import Spline from "@splinetool/react-spline";
import MockupDashboard from "../../Components/MockupDashboard/MockupDashboard";
import ModernPuzzle from "./PuzzleGraphic";
import BlueprintMap from "./BluePrintGraphic";
import { ServiceSection } from "../../modules/ServiceSection/ServiceSection";
import { GlowingCardType } from "../../Components/GlowingCard/GlowingCard";
import {
  Binary,
  Bot,
  Box,
  ChevronsLeftRightEllipsis,
  Code,
  DraftingCompass,
  GitPullRequest,
  GitPullRequestArrow,
  GraduationCap,
  HeartHandshake,
  LandPlot,
  PenTool,
  Repeat2,
  Search,
  Settings,
  Sparkles,
  Webhook,
} from "lucide-react";
import { getThemeColor } from "../../tailwindUtils";

const iconColor = getThemeColor("accent");

// Export the component with updated sections
export const AIAndAutomationSection = () => {
  const title = "AI & Automation";
  const description = "Gain the Edge - act Early, win Big";
  const cards: GlowingCardType[] = [
    {
      title: "Infrastructure",
      description:
        "We lay the foundation for your AI journey with scalable, secure systems.",
      icon: <Box className="h-4 w-4" color={iconColor} />,
      examples: [
        "Scalable cloud setups",
        "Secure data pipelines",
        "High-availability clusters",
        "Custom server configs",
      ],
    },
    {
      title: "Smart Agents",
      description:
        "Intelligent agents that adapt and learn, driving efficiency across processes.",
      icon: <Bot className="h-4 w-4" color={iconColor} />,
      examples: [
        "Chatbot automation",
        "Predictive analytics",
        "Personalized workflows",
        "Real-time decision tools",
      ],
    },
    {
      title: "Task Automation",
      description:
        "Streamline repetitive tasks with custom automation scripts tailored to your needs.",
      icon: <Repeat2 className="h-4 w-4" color={iconColor} />,
      examples: [
        "Data entry scripts",
        "Email automation flows",
        "Report generation tools",
        "Scheduling bots",
      ],
    },
    {
      title: "Custom Solutions",
      description:
        "Bespoke AI tools designed to solve your unique challenges with precision.",
      icon: <HeartHandshake className="h-4 w-4" color={iconColor} />,
      examples: [
        "Industry-specific models",
        "Custom API integrations",
        "Tailored dashboards",
        "Workflow optimization tools",
      ],
    },
  ];
  return (
    <ServiceSection
      element={
        <div className="md:w-full relative w-[200%] ">
          <Spline scene="https://prod.spline.design/BNrIOTyderelw8Wc/scene.splinecode" />
          <div className="bg-background h-[20%] md:h-[100px] w-[25%] md:w-[200px] bottom-0 right-0 absolute" />
        </div>
      }
      title={title}
      cards={cards}
      description={description}
    />
  );
};

export const WebDevelopmentSection = () => {
  const description = "Shape Tomorrow with Code Today";

  const cards: GlowingCardType[] = [
    {
      title: "UI/UX Design",
      icon: <PenTool className="h-4 w-4" color={iconColor} />,
      description:
        "Designing intuitive, beautiful interfaces that prioritize user experience.",
      examples: [
        "Wireframes & mockups",
        "User-centered design",
        "Interactive prototypes",
        "Brand-consistent visuals",
      ],
    },
    {
      title: "Modern Web Development",
      icon: <Settings className="h-4 w-4" color={iconColor} />,
      description:
        "Building cutting-edge websites with the latest technologies like React and Next.js.",
      examples: [
        "Single-page applications",
        "Server-side rendering",
        "Progressive web apps",
        "Headless CMS integration",
      ],
    },
    {
      title: "Tailored Solutions",
      icon: <Sparkles className="h-4 w-4" color={iconColor} />,
      description:
        "Delivering custom web solutions tailored to your specific business goals.",
      examples: [
        "E-commerce platforms",
        "Booking systems",
        "API integrations",
        "Scalable architectures",
      ],
    },
    {
      title: "Web Accessibility & Compliance",
      icon: <Search className="h-4 w-4" color={iconColor} />,
      description:
        "Making your web presence inclusive and compliant with global standards.",
      examples: [
        "Screen reader optimization",
        "Keyboard navigation",
        "ARIA implementation",
        "ADA compliance",
      ],
    },
  ];

  return (
    <ServiceSection
      title="Web Development"
      cards={cards}
      description={description}
    />
  );
};

export const CustomSolutionsSection = () => {
  const description = "Tame Challenges with Custom Craft";

  const cards: GlowingCardType[] = [
    {
      title: "API Development",
      icon: <Webhook className="h-4 w-4" color={iconColor} />,
      description:
        "Building robust APIs to connect and streamline your systems.",
      examples: [
        "RESTful endpoints",
        "GraphQL integrations",
        "Real-time data feeds",
        "Custom webhooks",
      ],
    },
    {
      title: "Internal Tools",
      icon: <DraftingCompass className="h-4 w-4" color={iconColor} />,
      description:
        "Custom tools to boost your team’s productivity and efficiency.",
      examples: [
        "Task management dashboards",
        "Employee onboarding apps",
        "Inventory trackers",
        "Reporting interfaces",
      ],
    },
    {
      title: "Specialized Software",
      description: "Tailored applications for your industry’s unique demands.",
      icon: <Code className="h-4 w-4" color={iconColor} />,
      examples: [
        "Healthcare portals",
        "E-learning platforms",
        "Financial trackers",
        "Logistics optimizers",
      ],
    },
    {
      title: "Integration Services",
      description:
        "Seamlessly connecting your existing tools with new solutions.",
      icon: <ChevronsLeftRightEllipsis className="h-4 w-4" color={iconColor} />,
      examples: [
        "CRM syncs",
        "Payment gateway links",
        "Third-party API hooks",
        "Data migration tools",
      ],
    },
  ];

  return (
    <ServiceSection
      title="Custom Solutions"
      cards={cards}
      description={description}
    />
  );
};

export const ConsultancySection = () => {
  const description = "Navigate Smart with Seasoned experts";

  const cards: GlowingCardType[] = [
    {
      title: "Technology Strategy",
      description: "Charting the path to tech success with a tailored roadmap.",
      icon: <Binary className="h-4 w-4" color={iconColor} />,
      examples: [
        "Tech stack audits",
        "Innovation workshops",
        "Scalability plans",
        "Adoption frameworks",
      ],
    },
    {
      title: "Solution Blueprints",
      description: "Designing custom solutions to fit your business goals.",
      icon: <LandPlot className="h-4 w-4" color={iconColor} />,
      examples: [
        "Workflow designs",
        "Prototype sketches",
        "Feasibility studies",
        "Implementation plans",
      ],
    },
    {
      title: "Tech Guidance",
      description: "Expert advice to navigate complex tech landscapes.",
      icon: <GitPullRequestArrow className="h-4 w-4" color={iconColor} />,
      examples: [
        "Vendor evaluations",
        "Tool selection",
        "Best practice reviews",
        "Risk assessments",
      ],
    },
    {
      title: "Industry Expertise",
      description:
        "Leveraging deep knowledge to solve industry-specific challenges.",
      icon: <GraduationCap className="h-4 w-4" color={iconColor} />,
      examples: [
        "Retail innovations",
        "Healthcare compliance",
        "E-commerce strategies",
        "Manufacturing insights",
      ],
    },
  ];

  return (
    <ServiceSection
      title="Consultancy"
      cards={cards}
      description={description}
    />
  );
};
