import React, { useState, useEffect, useRef, ReactElement } from "react";
import cn from "classnames";
import {
  GlowingCard,
  GlowingCardType,
} from "../../Components/GlowingCard/GlowingCard";

export interface TabContent {
  title: string;
  description: string;
  examples: string[];
}

interface ServiceSectionProps {
  title: string;
  description: string;
  reverse?: boolean;
  cards: GlowingCardType[];
  element?: ReactElement;
  className?: string;
}

export const ServiceSection: React.FC<ServiceSectionProps> = ({
  title,
  description,
  reverse,
  cards,
  element,
  className,
}) => {
  return (
    <div className={cn("min-h-screen relative overflow-hidden", className)}>
      <div
        className={`relative min-h-screen flex items-center justify-center gap-10 ${
          reverse ? "flex-col-reverse" : "flex-col"
        } w-full text-white px-2 p-10 md:p-12 ${element ? "!pb-0" : ""}`}
      >
        <div className="flex flex-col justify-center items-center text-center">
          <h1 className="heading-1 mb-4 lg:text-[9rem] ">{title}</h1>
          <p className="heading-4 mb-6 uppercase xl:max-w-[700px]">
            {description}
          </p>
        </div>
        <ul className="hidden flex flex-col items-center gap-4 w-full max-w-[100rem] mx-auto px-0 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-4">
          {cards.map((card, i) => {
            return (
              <GlowingCard
                icon={card.icon}
                title={card.title}
                description={card.description}
                examples={card.examples}
              />
            );
          })}
        </ul>
        {/* Start Mobile Slider */}
        <div className="md:hidden w-screen">
          <div className="px-5 pb-4 flex gap-4 relative overflow-x-scroll overflow-y-hidden h-[400px]">
            {cards.map((card, i) => {
              return (
                <GlowingCard
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                  examples={card.examples}
                  className="min-w-[300px] h-full"
                />
              );
            })}
          </div>
        </div>
        {/* End Mobile Slider */}
        {element && element}
      </div>
      {element && (
        <div className="absolute bottom-0 h-[100px] w-full bg-gradient-to-b from-transparent to-background w-full z-10" />
      )}
    </div>
  );
};
