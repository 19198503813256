import { useEffect, useRef } from "react";
import * as THREE from "three";

export const ParticleBackground = ({
  canvasRef,
  containerRef,
  isHoveringRef,
  mousePositionRef,
  lastMouseMoveRef,
}) => {
  const animationRef = useRef(null);
  const currentPositionsRef = useRef(null);
  const rotationTargetRef = useRef({ y: 0, z: 0 });
  const currentRotationRef = useRef({ y: 0, z: 0 });

  useEffect(() => {
    if (!canvasRef.current || !containerRef.current) return;

    const canvas = canvasRef.current;
    const container = containerRef.current;
    const width = container.clientWidth;
    const height = container.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
    });
    renderer.setSize(width, height);
    camera.position.z = 30;

    const particleCount = Math.min((width * height) / 800, 3000);
    const particlesGeometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);
    const colors = new Float32Array(particleCount * 3);
    const initialPositions = new Float32Array(particleCount * 3);

    currentPositionsRef.current = new Float32Array(particleCount * 3);

    const aspectRatio = width / height;
    const baseSpreadX = 50;
    const baseSpreadY = 50;
    const spreadX = baseSpreadX * Math.max(1, aspectRatio);
    const spreadY = baseSpreadY * Math.max(1, 1 / aspectRatio);
    const spreadZ = 15;

    for (let i = 0; i < particleCount; i++) {
      const i3 = i * 3;
      const x = (Math.random() - 0.5) * spreadX * 2;
      const y = (Math.random() - 0.5) * spreadY * 2;
      const z = (Math.random() - 0.5) * spreadZ * 2;

      positions[i3] = x;
      positions[i3 + 1] = y;
      positions[i3 + 2] = z;
      initialPositions[i3] = x;
      initialPositions[i3 + 1] = y;
      initialPositions[i3 + 2] = z;
      currentPositionsRef.current[i3] = x;
      currentPositionsRef.current[i3 + 1] = y;
      currentPositionsRef.current[i3 + 2] = z;

      sizes[i] = Math.random() * 0.8 + 0.1;

      const hue = 0.6 + Math.random() * 0.1;
      const saturation = 0.8 + Math.random() * 0.2;
      const lightness = 0.5 + Math.random() * 0.3;
      const c = (1 - Math.abs(2 * lightness - 1)) * saturation;
      const x1 = c * (1 - Math.abs(((hue * 6) % 2) - 1));
      const m = lightness - c / 2;

      let r, g, b;
      if (hue < 1 / 6) [r, g, b] = [c, x1, 0];
      else if (hue < 2 / 6) [r, g, b] = [x1, c, 0];
      else if (hue < 3 / 6) [r, g, b] = [0, c, x1];
      else if (hue < 4 / 6) [r, g, b] = [0, x1, c];
      else if (hue < 5 / 6) [r, g, b] = [x1, 0, c];
      else [r, g, b] = [c, 0, x1];

      colors[i3] = r + m;
      colors[i3 + 1] = g + m;
      colors[i3 + 2] = b + m;
    }

    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    particlesGeometry.setAttribute("size", new THREE.BufferAttribute(sizes, 1));
    particlesGeometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );

    const particlesMaterial = new THREE.ShaderMaterial({
      vertexShader: `
        attribute float size;
        attribute vec3 color;
        varying vec3 vColor;
        void main() {
          vColor = color;
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          gl_PointSize = size * (300.0 / -mvPosition.z);
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        varying vec3 vColor;
        void main() {
          float dist = length(gl_PointCoord - vec2(0.5));
          if (dist > 0.5) discard;
          float glow = 1.0 - smoothstep(0.2, 0.5, dist);
          vec3 glowColor = mix(vColor, vec3(1.0, 1.0, 1.0), 0.5) * glow;
          gl_FragColor = vec4(glowColor, glow);
        }
      `,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });

    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particles);

    let frame = 0;

    const handleResize = () => {
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    };

    window.addEventListener("resize", handleResize);

    const animate = () => {
      frame += 0.005;

      for (let i = 0; i < particleCount; i++) {
        const i3 = i * 3;
        const ix = initialPositions[i3];
        const iy = initialPositions[i3 + 1];
        const iz = initialPositions[i3 + 2];

        const waveX = Math.sin(frame + ix * 0.05) * 0.5;
        const waveY = Math.cos(frame + iy * 0.05) * 0.5;
        const waveZ = Math.sin(frame + iz * 0.02) * 0.3;

        let targetX = ix + waveX;
        let targetY = iy + waveY;
        let targetZ = iz + waveZ;

        if (isHoveringRef.current) {
          const mouseX =
            (mousePositionRef.current.x / container.clientWidth) * 2 - 1;
          const mouseY = -(
            (mousePositionRef.current.y / container.clientHeight) * 2 -
            1
          );
          const timeSinceLastMove = Date.now() - lastMouseMoveRef.current;
          const influenceDecay = Math.max(0, 1 - timeSinceLastMove / 1000);

          const dx = targetX - mouseX * 25;
          const dy = targetY - mouseY * 25;
          const dist = Math.sqrt(dx * dx + dy * dy);

          if (dist < 10) {
            const repelForce =
              (1 - Math.min(1, dist / 50)) * 2 * influenceDecay;
            const dirX = dx / dist || 0;
            const dirY = dy / dist || 0;
            targetX += dirX * repelForce;
            targetY += dirY * repelForce;
            targetZ += 0.1 * repelForce;
          } else {
            const attractForce =
              Math.min(0.1, Math.max(0, (dist - 10) / 50)) * influenceDecay;
            const dirX = dx / dist || 0;
            const dirY = dy / dist || 0;
            targetX -= dirX * attractForce;
            targetY -= dirY * attractForce;
          }

          const vortexStrength = 0.1 * influenceDecay;
          const vortexX = (-dy / (dist * dist + 1)) * vortexStrength;
          const vortexY = (dx / (dist * dist + 1)) * vortexStrength;
          targetX += vortexX;
          targetY += vortexY;

          const rotationSmoothingFactor = 0.02;
          const targetYRotation = mouseX * 0.3;
          const targetZRotation = mouseY * 0.2;
          rotationTargetRef.current.y +=
            (targetYRotation - rotationTargetRef.current.y) *
            rotationSmoothingFactor;
          rotationTargetRef.current.z +=
            (targetZRotation - rotationTargetRef.current.z) *
            rotationSmoothingFactor;
        } else {
          rotationTargetRef.current.y *= 0.95;
          rotationTargetRef.current.z *= 0.95;
        }

        const smoothingFactor = 0.05;
        currentPositionsRef.current[i3] +=
          (targetX - currentPositionsRef.current[i3]) * smoothingFactor;
        currentPositionsRef.current[i3 + 1] +=
          (targetY - currentPositionsRef.current[i3 + 1]) * smoothingFactor;
        currentPositionsRef.current[i3 + 2] +=
          (targetZ - currentPositionsRef.current[i3 + 2]) * smoothingFactor;

        positions[i3] = currentPositionsRef.current[i3];
        positions[i3 + 1] = currentPositionsRef.current[i3 + 1];
        positions[i3 + 2] = currentPositionsRef.current[i3 + 2];
      }

      particlesGeometry.attributes.position.needsUpdate = true;

      const rotationApplyFactor = 0.05;
      currentRotationRef.current.y +=
        (rotationTargetRef.current.y - currentRotationRef.current.y) *
        rotationApplyFactor;
      currentRotationRef.current.z +=
        (rotationTargetRef.current.z - currentRotationRef.current.z) *
        rotationApplyFactor;

      particles.rotation.y = currentRotationRef.current.y;
      particles.rotation.z = currentRotationRef.current.z;

      camera.position.x = Math.sin(frame * 0.1) * 2;
      camera.position.y = Math.cos(frame * 0.1) * 2;
      camera.lookAt(0, 0, 0);

      renderer.render(scene, camera);
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationRef.current);
      scene.remove(particles);
      particlesGeometry.dispose();
      particlesMaterial.dispose();
      renderer.dispose();
    };
  }, [
    canvasRef,
    containerRef,
    isHoveringRef,
    mousePositionRef,
    lastMouseMoveRef,
  ]);

  return null;
};
