import { Box, Search, Settings, Sparkles } from "lucide-react";
import { GlowingEffect } from "./GlowingEffect";
import cn from "classnames";

export function GlowingEffectDemo() {
  return (
    <ul className="flex flex-col items-center gap-4 mx-auto px-4 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-4">
      <GlowingCard
        icon={<Box className="h-4 w-4" />}
        title="Do things the right way"
        description="Running out of copy so I'll write anything."
      />
      <GlowingCard
        icon={<Settings className="h-4 w-4" />}
        title="The best AI code editor ever."
        description="Yes, it's true. I'm not even kidding. Ask my mom if you don't believe me."
      />
      <GlowingCard
        icon={<Sparkles className="h-4 w-4" />}
        title="This card is also built by Cursor"
        description="I'm not even kidding. Ask my mom if you don't believe me."
      />
      <GlowingCard
        icon={<Search className="h-4 w-4" />}
        title="Coming soon on Aceternity UI"
        description="I'm writing the code as I record this, no shit."
      />
    </ul>
  );
}

export interface GlowingCardType {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  className?: string;
  examples?: string[];
}

export const GlowingCard = ({
  icon,
  title,
  description,
  className,
  examples,
}: GlowingCardType) => {
  return (
    <li className={cn("h-full list-none w-full", className)}>
      <div className="relative h-full rounded-[1.25rem] border-[0.75px] border-stroke p-2 md:rounded-[1.5rem] md:p-3">
        <GlowingEffect
          spread={40}
          glow={true}
          disabled={false}
          proximity={64}
          inactiveZone={0.01}
          variant="default"
          borderWidth={3}
        />
        <div className="relative flex h-full flex-col justify-between gap-6 overflow-hidden rounded-xl border-[0.75px] bg-background border-stroke p-6 shadow-sm dark:shadow-[0px_0px_27px_0px_rgba(45,45,45,0.3)] md:p-6">
          <div className="relative flex flex-1 flex-col gap-3">
            <div className="w-fit rounded-lg border-[0.75px] border-stroke bg-muted p-2">
              {icon}
            </div>
            <div className="space-y-3">
              <h3 className="pt-0.5 text-xl leading-[1.375rem] font-semibold font-sans tracking-[-0.04em] md:text-2xl md:leading-[1.875rem] text-balance">
                {title}
              </h3>
              <h2 className="[&_b]:md:font-semibold [&_strong]:md:font-semibold font-sans text-sm leading-[1.125rem] md:text-base md:leading-[1.375rem] text-[#b3bfca]">
                {description}
              </h2>
            </div>
          </div>
          {examples && examples.length > 0 && (
            <div className="relative mt-4 overflow-hidden">
              <div className="absolute inset-y-0 left-0 w-8 bg-gradient-to-r from-background to-transparent z-10" />
              <div className="absolute inset-y-0 right-0 w-8 bg-gradient-to-l from-background to-transparent z-10" />
              <div className="flex items-center justify-center flex-wrap">
                {examples.map((example, index) => (
                  <span
                    key={index}
                    className="inline-block text-xs text-muted-foreground whitespace-nowrap p-2"
                  >
                    {example}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
