import { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { TextMask } from "../../Components/TextMask/TextMask";
import { CTA } from "../../Components/CTA/CTA";
import { useNavigate } from "react-router";
import { ParticleBackground } from "./HeroParticles";
import { LogoSvg } from "../../Utils/svgs/logo";
import { getThemeColor } from "../../tailwindUtils";
import TextScramble from "../../Components/ScrableText/ScrableText";

export const HeroSection = () => {
  const containerRef = useRef<any>(null);
  const canvasRef = useRef(null);
  const mousePositionRef = useRef({ x: 0, y: 0 });
  const isHoveringRef = useRef(false);
  const lastMouseMoveRef = useRef(Date.now());
  const controls = useAnimation();
  const navigate = useNavigate();

  const handleMouseMove = (event: any) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const newMousePosition = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    mousePositionRef.current = newMousePosition;
    lastMouseMoveRef.current = Date.now();

    const velocityX = Math.abs(newMousePosition.x - mousePositionRef.current.x);
    const velocityY = Math.abs(newMousePosition.y - mousePositionRef.current.y);
    const velocity = Math.min(10, velocityX + velocityY);

    if (velocity > 5) {
      controls.start({
        scale: [0, 1],
        opacity: [0.7, 0],
        transition: { duration: 1 },
      });
    }
  };

  const titleWords = "Tech Partner for Every Vision";
  const phrases = [
    "Artificial Intelligence",
    "Web Development",
    "Custom Software",
    "Tailored Strategies",
  ];

  return (
    <div
      ref={containerRef}
      className="relative overflow-hidden w-full h-screen bg-black"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => (isHoveringRef.current = true)}
      onMouseLeave={() => (isHoveringRef.current = false)}
    >
      <canvas ref={canvasRef} className="absolute inset-0 z-0" />
      <ParticleBackground
        canvasRef={canvasRef}
        containerRef={containerRef}
        isHoveringRef={isHoveringRef}
        mousePositionRef={mousePositionRef}
        lastMouseMoveRef={lastMouseMoveRef}
      />
      <motion.div
        className="pointer-events-none absolute rounded-full bg-blue-500 opacity-20 mix-blend-screen z-10"
        animate={controls}
        style={{
          width: 100,
          height: 100,
          x: mousePositionRef.current.x - 50,
          y: mousePositionRef.current.y - 50,
          scale: 0,
        }}
      />
      <div className="absolute inset-0 z-20 flex flex-col items-center justify-center gap-10 text-center px-4 lg:px-16">
        <div>
          <TextScramble
            className="heading-3 text-center text-accent uppercase"
            phrases={phrases}
          />
          <h1 className="heading-1">
            <TextMask title={titleWords} />
          </h1>
        </div>
        <motion.div className="z-10">
          <CTA label="Contact us" action={() => navigate("/form/discovery")} />
        </motion.div>
      </div>
    </div>
  );
};
