import { useEffect, useRef } from "react";

interface TextScrambleProps {
  phrases: string[];
  className?: string;
}

export const TextScramble = ({ phrases, className }: TextScrambleProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number>(0);
  const queueRef = useRef<
    { from: string; to: string; start: number; end: number; char?: string }[]
  >([]);
  const frameRef = useRef(0);
  const resolveRef = useRef<(() => void) | null>(null);
  const chars = "!<>-_\\/[]{}—=+*^?#________";

  const randomChar = () => chars[Math.floor(Math.random() * chars.length)];

  const setText = (newText: string) => {
    if (!textRef.current) return Promise.resolve();

    const oldText = textRef.current.innerText;
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise<void>(
      (resolve) => (resolveRef.current = resolve)
    );

    queueRef.current = Array.from({ length }, (_, i) => ({
      from: oldText[i] || "",
      to: newText[i] || "",
      start: Math.floor(Math.random() * 40),
      end: Math.floor(Math.random() * 40) + Math.floor(Math.random() * 40),
    }));

    cancelAnimationFrame(animationRef.current);
    frameRef.current = 0;
    update();

    return promise;
  };

  const update = () => {
    if (!textRef.current) return;

    let output = "";
    let complete = 0;

    queueRef.current.forEach((item, i) => {
      const { from, to, start, end } = item;

      if (frameRef.current >= end) {
        complete++;
        output += to;
      } else if (frameRef.current >= start) {
        if (!item.char || Math.random() < 0.28) {
          item.char = randomChar();
        }
        output += `<span class="text-gray-500">${item.char}</span>`;
      } else {
        output += from;
      }
    });

    textRef.current.innerHTML = output;

    if (complete === queueRef.current.length && resolveRef.current) {
      resolveRef.current();
    } else {
      animationRef.current = requestAnimationFrame(update);
      frameRef.current++;
    }
  };

  useEffect(() => {
    let counter = 0;
    let mounted = true;

    const next = () => {
      if (!mounted) return;

      setText(phrases[counter]).then(() => {
        setTimeout(next, 800);
      });
      counter = (counter + 1) % phrases.length;
    };

    next();

    return () => {
      mounted = false;
      cancelAnimationFrame(animationRef.current);
    };
  }, [phrases]);

  return (
    <div className={className}>
      <div ref={textRef} className="inline-block" />
    </div>
  );
};

export default TextScramble;
