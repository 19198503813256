import { heroImpactCardsTranslations } from "./heroImpactTranslations";
import { homeHeroTranslations } from "./homeHeroTranslations";

export const homeTranslations = {
  hero: homeHeroTranslations,
  workWith: {
    en: "Collaborate with top talent and teams from around the globe",
    de: "Arbeiten Sie mit Top-Talenten und Teams aus der ganzen Welt",
    es: "Colabora con los mejores talentos y equipos de todo el mundo",
  },
  ourImpact: {
    title: {
      en: "Our Impact by Numbers",
      de: "Unsere Wirkung in Zahlen",
      es: "Nuestro Impacto en Números",
    },
    description: {
      en: "Our achievements across web development, blockchain, and AI solutions.",
      de: "Unsere Erfolge in den Bereichen Webentwicklung, Blockchain und KI.",
      es: "Nuestros logros en desarrollo web, blockchain y soluciones de IA.",
    },
    cards: heroImpactCardsTranslations,
  },
};
