import { useRef } from "react";
import { Card } from "../Card/Card";
import useScrollTriggeredCountUp from "../../hooks/useCountUp";
import "./NumberCard.scss"; // Make sure to create this CSS file
import { GlowingEffect } from "../GlowingCard/GlowingEffect";

export type NumberCardType = {
  number: number;
  symbol?: string;
  text: string;
  className?: string;
  img?: string;
  title?: string;
};

export const NumberCard = ({
  number,
  symbol = "",
  text,
  className,
  img,
  title,
}: NumberCardType) => {
  const ref = useRef<HTMLDivElement>(null);
  const count = useScrollTriggeredCountUp(ref, number);

  return (
    <div
      className={`number-card-container relative rounded-[1.25rem] border-[0.75px] border-stroke p-2 md:rounded-[1.5rem] md:p-3 ${
        !img ? "no-image" : ""
      } ${className} `}
    >
      <GlowingEffect
        spread={40}
        glow={true}
        disabled={false}
        proximity={64}
        inactiveZone={0.01}
        variant="default"
        borderWidth={3}
      />
      <div
        className={`number-card-content ${className}`}
        style={{
          backgroundImage: img
            ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${img})`
            : "none",
        }}
      >
        <div
          ref={ref}
          className="number-card-value text-4xl text-accent drop-shadow-xl md:text-6xl 2xl:text-6xl font-extrabold"
        >
          [{count}
          <span className="text-xl md:text-4xl">{symbol}</span>]
        </div>
        <div className="number-card-text flex flex-col gap-2 mt-20">
          <h1 className="heading-2">{title}</h1>
          <h3 className="body-2">{text}</h3>
        </div>
      </div>
    </div>
  );
};
