export const aiHeroTranslations = {
  first: {
    en: "AI adoption for business",
    es: "Adopción de IA para empresas",
    de: "KI-Einführung für Unternehmen",
  },
  strings: {
    a: {
      en: "Streamlined",
      es: "Simplificado",
      de: "Beschleunigt",
    },
    b: {
      en: "Simplified",
      es: "Optimizado",
      de: "Vereinfacht",
    },
    c: {
      en: "Accelerated",
      es: "Acelerado",
      de: "Optimiert",
    },
    d: {
      en: "Seamless",
      es: "Fluido",
      de: "Nahtlos",
    },
  },
  last: {
    en: "",
    es: "",
    de: "",
  },
  text: {
    en: "We partner with visionaries to navigate the rapidly evolving AI landscape, exploring possibilities, making optimal decisions, and ensuring seamless implementation and effective team integration.",
    es: "Nos asociamos con visionarios para navegar en el paisaje de IA en constante evolución, explorando posibilidades, tomando decisiones óptimas y asegurando una implementación fluida y una integración efectiva del equipo.",
    de: "Wir arbeiten mit Visionären zusammen, um im sich schnell entwickelnden KI-Bereich zu navigieren, Möglichkeiten zu erkunden, optimale Entscheidungen zu treffen und eine nahtlose Implementierung sowie eine effektive Teamintegration sicherzustellen.",
  },
  ctaCard: {
    buttonText: {
      en: "Book free call",
      es: "Reservar llamada gratuita",
      de: "Kostenlosen Anruf buchen",
    },
    headline: { en: "Headline", es: "Título", de: "Überschrift" },
    text: {
      en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      es: "El panorama empresarial del futuro tendrá aquellos que hayan abrazado la IA y aquellos que desearían haberlo hecho",
      de: "Die Geschäftslandschaft der Zukunft wird diejenigen haben, die KI angenommen haben und diejenigen, die es wünschten",
    },
  },
};
