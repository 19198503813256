import React from "react";
import logo from "../../Assets/logo.png";
import { FadeSeparator } from "../../Components/FadeSeparator/FadeSeparator";
import { InteractiveLogo } from "../../Utils/InteractiveLogo";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "../../hooks/useTranslation";
import { LanguageSelector } from "../../Components/LanguageSelector/LanguageSelector";
import { headerLinks } from "../Header_old/Header";
import { CTA } from "../../Components/CTA/CTA";

export const Footer = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  return (
    <div className="p-5 md:p-40 bg-background text-main flex-col md:flex-col flex items-center justify-between relative gap-10">
      <FadeSeparator className="top-0 -translate-x-1/2 left-1/2" />
      <div className="flex items-center w-fit flex-col gap-10">
        {InteractiveLogo()}
      </div>
      {<CTA label="Contact us" action={() => navigate("/form/discovery")} />}

      <div>
        <p className="body-3">MillerioTech © {new Date().getFullYear()}</p>
      </div>
    </div>
  );
};
