export const heroImpactCardsTranslations = [
  {
    title: {
      en: "Developers",
      es: "Desarrolladores",
      de: "Entwickler",
    },
    text: {
      en: "Our dynamic team consists of over 60 skilled developers, enabling us to tackle challenges and innovate at every turn.",
      es: "Nuestro equipo dinámico está compuesto por más de 60 desarrolladores cualificados, lo que nos permite enfrentar desafíos e innovar en cada paso.",
      de: "Unser dynamisches Team besteht aus über 60 qualifizierten Entwicklern, die es uns ermöglichen, Herausforderungen zu bewältigen und bei jeder Gelegenheit zu innovieren.",
    },
  },
  {
    title: {
      en: "Projects",
      es: "Proyectos",
      de: "Projekte",
    },
    text: {
      en: "We have successfully completed over 100 projects, spanning cutting-edge web development, AI integrations, decentralized applications, and strategic consultancy services. Each project is a testament to our commitment to innovation and excellence.",
      es: "Hemos completado con éxito más de 100 proyectos que abarcan el desarrollo web de vanguardia, integraciones de IA, aplicaciones descentralizadas y servicios de consultoría estratégica. Cada proyecto es un testimonio de nuestro compromiso con la innovación y la excelencia.",
      de: "Wir haben erfolgreich über 100 Projekte abgeschlossen, die von modernster Webentwicklung, AI-Integrationen, dezentralen Anwendungen bis hin zu strategischen Beratungsdiensten reichen. Jedes Projekt ist ein Zeugnis unseres Engagements für Innovation und Exzellenz.",
    },
  },
  {
    title: {
      en: "Image",
      es: "Imagen",
      de: "Bild",
    },
    text: {
      en: "Join our thriving community of 100,000 users and experience the power of Nexus firsthand.",
      es: "Únete a nuestra próspera comunidad de 100,000 usuarios y experimenta el poder de Nexus de primera mano.",
      de: "Treten Sie unserer blühenden Gemeinschaft von 100.000 Benutzern bei und erleben Sie die Kraft von Nexus aus erster Hand.",
    },
  },
  {
    title: {
      en: "Countries",
      es: "Países",
      de: "Länder",
    },
    text: {
      en: "With talented developers across more than 10 countries, we bring global expertise to every project we touch.",
      es: "Con desarrolladores talentosos en más de 10 países, aportamos experiencia global a cada proyecto que tocamos.",
      de: "Mit talentierten Entwicklern in mehr als 10 Ländern bringen wir globale Expertise in jedes Projekt, das wir betreuen.",
    },
  },
  {
    title: {
      en: "Users Reached",
      es: "Usuarios Alcanzados",
      de: "Erreichte Benutzer",
    },
    text: {
      en: "Our applications and platforms serve over half a million users worldwide, demonstrating our ability to build scalable solutions.",
      es: "Nuestras aplicaciones y plataformas sirven a más de medio millón de usuarios en todo el mundo, demostrando nuestra capacidad para desarrollar soluciones escalables.",
      de: "Unsere Anwendungen und Plattformen bedienen weltweit über eine halbe Million Benutzer, was unsere Fähigkeit unter Beweis stellt, skalierbare Lösungen zu entwickeln.",
    },
  },
];
