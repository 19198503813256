import { NumberCardType } from "../../Components/NumberCard/NumberCard";
import OfficeImage from "../../Assets/office.jpg";
import OutdoorImage from "../../Assets/outdoor.jpg";

export const impactData: NumberCardType[] = [
  {
    number: 100,
    title: "Projects",
    text: "Over 100 projects completed, spanning web development, AI integrations, decentralized applications, and consultancy services.",
    symbol: "+",
    img: OfficeImage,
  },
  {
    number: 500,
    title: "Users Reached",
    text: "Our applications and platforms serve over half a million users worldwide, demonstrating our ability to build scalable solutions.",
    symbol: "k",
  },
  {
    number: 60,
    title: "Developers",
    text: "Our dynamic team consists of over 60 skilled developers, enabling us to tackle challenges and innovate at every turn.",
    symbol: "+",
    img: OutdoorImage,
  },
  {
    number: 10,
    title: "Countries",
    text: "With talented developers across more than 10 countries, we bring global expertise to every project we touch.",
    symbol: "+",
  },
];
