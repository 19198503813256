export const homeHeroTranslations = {
  first: {
    en: "Software solutions & innovation lab",
    es: "Soluciones de software y laboratorio de innovación",
    de: "Softwarelösungen & Innovationslabor",
  },
  strings: {
    a: {
      en: "Web Development",
      es: "Desarrollo Web",
      de: "Webentwicklung",
    },
    b: {
      en: "Artificial Intelligence",
      es: "Inteligencia Artificial",
      de: "Künstliche Intelligenz",
    },
    c: {
      en: "Web3 & Blockchain",
      es: "Web3 y Blockchain",
      de: "Web3 & Blockchain",
    },
    d: {
      en: "Software Solutions",
      es: "Soluciones de Software",
      de: "Softwarelösungen",
    },
  },
  last: {
    en: "",
    es: "",
    de: "",
  },
  text: {
    en: "Web development, AI, Web3, and any digital challenge you throw our way. If you can dream it, we can build it.",
    es: "El desarrollo web es nuestro hogar, pero nuestro juego se extiende al AI, Web3 y cualquier desafío digital que nos propongas. Si puedes soñarlo, podemos construirlo.",
    de: "Webentwicklung ist unser Zuhause, aber unser Spiel erstreckt sich auf KI, Web3 und jede digitale Herausforderung, die Sie uns bieten. Wenn Sie es träumen können, können wir es bauen.",
  },
  ctaCard: {
    buttonText: {
      en: "Book free call",
      es: "Reservar llamada gratuita",
      de: "Kostenlosen Anruf buchen",
    },
    headline: { en: "Headline", es: "Título", de: "Überschrift" },
    text: {
      en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      es: "El panorama empresarial del futuro tendrá aquellos que hayan abrazado la IA y aquellos que desearían haberlo hecho",
      de: "Die Geschäftslandschaft der Zukunft wird diejenigen haben, die KI angenommen haben und diejenigen, die es wünschten",
    },
  },
};
